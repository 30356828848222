.tempStyle {
  @apply text-primary !important;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @apply text-primary;
  }
}
