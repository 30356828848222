.base {
  @apply relative rounded-default
  bg-primary
  px-8 py-3
  leading-4
  inline-flex
  items-center
  font-semibold
  cursor-pointer
  overflow-hidden
  transition-all duration-300 ease-in-out
  focus:outline-none;
}
.loadingButtom{
  transition:all 0.3s ease-in-out;
  margin-inline: 0px;
  aspect-ratio: 1/1;
  opacity:0;
  max-width: 0px;
  max-height: 0px;
}
.loadingButtom.active{

  margin-inline: 10px;
  aspect-ratio: 1/1;
  opacity:1;
  max-width: 25px;
  max-height: 25px;
}